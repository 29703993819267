<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 256 256"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_28_32)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M108.325 19.9051C59.4922 19.9051 19.9051 59.4922 19.9051 108.325C19.9051 157.159 59.4922 196.746 108.325 196.746C132.745 196.746 154.836 186.86 170.848 170.848C186.86 154.836 196.746 132.745 196.746 108.325C196.746 59.4922 157.159 19.9051 108.325 19.9051ZM0 108.325C0 48.4989 48.4989 0 108.325 0C168.152 0 216.651 48.4989 216.651 108.325C216.651 134.651 207.25 158.796 191.638 177.563L253.085 239.01C256.972 242.897 256.972 249.198 253.085 253.085C249.198 256.972 242.897 256.972 239.01 253.085L177.563 191.638C158.796 207.25 134.651 216.651 108.325 216.651C48.4989 216.651 0 168.152 0 108.325Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
